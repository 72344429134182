export default class Shain {
  userId: string;
  userName: string;
  password: string;
  authority: string;
  isValid: string;
  department: string[];
  constructor(
    userId = "",
    userName = "",
    password = "",
    authority = "",
    isValid = "",
    department = []
  ) {
    this.userId = userId;
    this.userName = userName;
    this.password = password;
    this.authority = authority;
    this.isValid = isValid;
    this.department = department;
  }
}
